import {
  createClient as createURQLClient,
  mapExchange,
  subscriptionExchange,
} from '@urql/vue';
import { createClient as createWSClient } from 'graphql-ws';

import { cacheExchange } from '@urql/exchange-graphcache';
import { fetchExchange } from '@urql/core';

import Cookies from 'js-cookie';
import { parse } from 'date-fns';
import { Kind } from 'graphql';
import schema from './schema.json';
import { resolveISO } from './utils/date.js';

// TODO: rethink how we use these fragments

const getOperationName = (query) => {
  for (let i = 0, l = query.definitions.length; i < l; i += 1) {
    const node = query.definitions[i];
    if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
      return node.name.value;
    }
  }
  return null;
};

const invalidateAllQueries = (cache, fieldName) => {
  const queries = cache.inspectFields('Query').filter((q) => q.fieldName === fieldName);
  queries.forEach((q) => {
    cache.invalidate('Query', fieldName, q.arguments);
  });
};

const transformToDate = (parent, _args, _cache, info) => resolveISO(parent[info.fieldName]);
const transformToFloat = (parent, _args, _cache, info) => ((parent[info.fieldName] !== null)
  ? Number.parseFloat(parent[info.fieldName]) : parent[info.fieldName]);

export default function createClient(isClient, token) {

  const url = import.meta.env.VITE_GRAPHQL_ENDPOINT;
  const release = import.meta.env.RELEASE;
  const wsClient = (isClient) ? createWSClient({
    url: url.replace(/http/, 'ws'),
    lazy: true,
    keepAlive: 60 * 1000,
  }) : null;

  return {
    websocketClient: wsClient,
    client: createURQLClient({
      url,
      preferGetMethod: false,
      exchanges: [
        // devtoolsExchange,
        // refocusExchange(),
        // requestPolicyExchange({
        //   ttl: 5 * 60 * 1000,
        // }),
        cacheExchange({
          schema: schema.data,
          keys: {
            ChartLayer: (cl) => cl.code,
            Point: (p) => [p.latitude, p.longitude],
          },
        }),
        subscriptionExchange({
          forwardSubscription: (operation) => ({
            subscribe: (sink) => ({
              unsubscribe: wsClient.subscribe({
                // Rewrite urqls SubscriptionPayload to graphql-ws's payload
                variables: operation.variables,
                query: operation.query,
                // XXX: Very ugly, but it works. We would rather have
                //      urql expose the operationName, but it flattens its Operation in this object
                operationName: operation.operationName,
              }, sink),
            }),
          }),
        }),
        fetchExchange,
      ],
      fetchOptions: () => {
        const CSRFToken = Cookies.get('csrftoken');

        if (import.meta.env.PROD) {
          try {
            const CSRFTokenChecked = localStorage.getItem('urqlCSRFCheck') || false;
            // We reload once, which should give us a token
            if (!CSRFToken && !CSRFTokenChecked && typeof window !== 'undefined') {
              localStorage.setItem('urqlCSRFCheck', 'true');
              window.location.reload();
            } else if (CSRFToken && CSRFTokenChecked) {
              localStorage.removeItem('urqlCSRFCheck');
            }
          } catch {
            // If LocalStorage gives us SecurityError, so be it.
          }
        }

        const currentLang = (typeof document === 'undefined') ? 'nl' : document.documentElement.lang;
        return {
          headers: {
            'X-CSRFToken': CSRFToken || undefined,
            'X-Release': release,
            'Accept-Language': currentLang,
            Referer: import.meta.env.VITE_HOST,
            Authorization: (token?.accessToken) ? `Bearer ${token.accessToken}` : undefined,
          },
        };
      },

    }),
  };
}
