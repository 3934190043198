// router.js
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from 'vue-router';

//
// Utility views
//

const NotFoundView = () => import('./views/NotFoundView.vue');

//
// Main views
//
const MainContainer = () => import('./components/main/MainContainer.vue');
const HomeView = () => import('./views/HomeView.vue');
const EditorView = () => import('./views/EditorView.vue');

const mode = import.meta.env.MODE;

function getRoutesFor(i18n, locale, prefix) {
  const localizedPath = (path) => {
    const translatedPath = i18n.t(`route.${path}`, null, { locale });
    if (prefix && path.indexOf('.') === -1) {
      return `${prefix}/${translatedPath}`;
    }
    return translatedPath;
  };

  return [
    {
      path: prefix,
      name: `${locale}:home`,
      component: HomeView,
    },
    {
      path: localizedPath('editor'),
      name: `${locale}:editor`,
      component: EditorView,
    },
    // Catch all for 404 page, localised.
    {
      path: (prefix) ? `${prefix}/:path` : ':path',
      name: `${locale}:notFound`,
      component: NotFoundView,
    },
  ];
}

export function createAppRouter(i18n) {
  const routes = [
    {
      path: '/',
      component: MainContainer,
      children: [
        ...getRoutesFor(i18n, 'nl', ''),
      ],
    },
  ];

  const router = createRouter({
    history: (import.meta.env.SSR) ? createMemoryHistory() : createWebHistory(),
    routes,
    ...((import.meta.env.SSR) ? {} : {
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(savedPosition);
            });
          });
        }
        if (to.name === from.name) {
          return null;
        }
        return { left: 0, top: 0 };
      },
    }),
  });
  router.beforeEach(async (to, from, next) => {
    if (to.matched.length > 0) {
      const toLocale = to.name && to.name.split(':')[0];

      if (to.name.indexOf(':') !== -1 && i18n && i18n.locale.value !== toLocale) {

        // eslint-disable-next-line no-param-reassign
        i18n.locale.value = toLocale;

        if (!import.meta.env.SSR) {
          document.documentElement.lang = toLocale;
        }
      }

    }
    return next();
  });
  return router;
}

export default createAppRouter;
