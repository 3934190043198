<template>
  <NavigationGuard
      :permission="permission">
    <router-view />
    <template #permission-denied>
      <LoginView />
    </template>
  </NavigationGuard>
</template>

<i18n>
{
  "nl": {
    "title": "Kaarten"
  }
}
</i18n>
<script>

import { useI18n } from 'vue-i18n';
import {
  computed,
  provide,
} from 'vue';
import { useHead } from '@unhead/vue';
import useSession from './session/session.js';
import NavigationGuard from './components/navigation/NavigationGuard.vue';
import LoginView from './views/session/LoginView.vue';

export default {
  name: 'Application',
  components: { LoginView, NavigationGuard },
  setup() {
    const { t } = useI18n();
    const { user, sessionInitialised } = useSession();

    provide('windowed', false);

    useHead({
      titleTemplate: (title) => {
        if (title === undefined) {
          // Do not update if we do not have a title set
          return null;
        }
        return ((title) ? `${title} – ${t('title')}` : t('title'));
      },
    });

    const permission = computed(() => (!!sessionInitialised.value && !!user.value));

    return {
      t,

      permission,
      sessionInitialised,
    };
  },
};
</script>
