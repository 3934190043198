import './stylesheets/settings/colors.css';
import './stylesheets/settings/animations.css';
import './stylesheets/settings/dimensions.css';
import './stylesheets/settings/fonts.css';
import './stylesheets/settings/layers.css';

import 'reset-css/reset.css';
import './stylesheets/index.css';
import {
  createApp,
  ref,
} from 'vue';
import { createHead } from '@unhead/vue';
import urql from '@urql/vue';
import createRouter from './router.js';
import createClient from './graphql.js';
import createI18N from './i18n.js';
import App from './Application.vue';
import { createSession } from './session/session.js';

export default function createApplication(router, i18n) {
  const app = createApp(App);
  const { client, websocketClient } = createClient(true, i18n);
  const gqlClient = ref(client);
  const gqlWebsocketClient = ref(websocketClient);
  app.use(router);
  app.use(createHead());
  app.use(urql, gqlClient);
  app.use(i18n);
  const session = createSession(gqlClient, () => {
    const { client: newClient, websocketClient: newWSClient } = createClient(true, i18n);
    gqlClient.value = newClient;
    if (gqlWebsocketClient.value) {
      gqlWebsocketClient.value.dispose();
    }
    gqlWebsocketClient.value = newWSClient;
  });
  app.use(session);
  return app;
}

const release = `${import.meta.env.RELEASE}`;
document.body.dataset.release = release;

const { lang } = document.documentElement;
const i18n = createI18N(lang || 'nl');
const router = createRouter(i18n.global);

const app = createApplication(router, i18n);

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  app.mount('#application');
});
